
import React from 'react';
import './arrows.scss';

export const PreviousArrow = function(props) {
  const { style, onClick } = props;
  return (
    <div
    className={`${2} prev`}
    style={{ ...style, display: "block" }}
    onClick={onClick}
    >
        <span>&#8592;</span>
    </div>
  )
}

export const NextArrow = function(props) {
    const { style, onClick } = props;
    return (
      <div
      className={`next`}
      style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <span>&rarr;</span>
      </div>
    )
}
