import React, { Fragment } from 'react';
import Navigation from '../components/navigation/navigation.component';
import Footer from '../components/footer/footer.component';
import { Route } from 'react-router-dom';

export const HomeLayout = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <Fragment>
            <Navigation></Navigation>
            <Component {...props} />
            <Footer></Footer>
        </Fragment>
    )} />
)