import './footer.styles.scss';
import React from 'react';
import LineIcon from 'react-lineicons';
import Logo2 from '../../../src/assets/logo-light.svg';

const Footer = () =>  (
    <div className='footer'>
         <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='logo-wrapper'>
                            <img src={Logo2} alt='logo'/>
                        </div>
                        <ul className='contact'>
                            <li><a href="mailto:artandcodes@gmail.com">artandcodes@gmail.com</a></li> 
                            <li><a href="tel:+2347066365036" className="tel">+234 706 636 5036</a>, <a href="tel:+2348164334657" className="tel">+234 816 433 4657</a></li>
                        </ul>
                        <ul className='socials'>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://facebook.com/artandcodes"><LineIcon size='sm' effect='tada' name="facebook" /></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://instagram.com/artandcodes"><LineIcon size='sm' effect='tada' name="instagram" /></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/art-and-codes/"><LineIcon size='sm' effect='tada' name="linkedin" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='bottom-footer'>
                &copy; 2020 ArtandCodes. All rights reserved
            </div>
    </div>
)

export default Footer;