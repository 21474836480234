import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Homepage from './pages/homepage/homepage.component';
import { HomeLayout } from './layouts/home-layout.component';

function App(props) {
  return (
      <Router>
        <Switch>
          <HomeLayout exact path="/" component={Homepage} />
          <Redirect from="*" to="/" />
        </Switch>
    </Router>
  );
}

export default App;
