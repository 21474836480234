import './navigation.styles.scss';
import React, { Component } from 'react';
import { Link , Events, scrollSpy } from 'react-scroll'
import { NavLink} from 'react-router-dom'
import LineIcon from 'react-lineicons';
import LogoDark from '../../../src/assets/logo-dark.svg';
import LogoLight from '../../../src/assets/logo-light.svg';

class Navigation extends Component {


    state = {
        isOpen: false,
        isNavFixed: false 
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        Events.scrollEvent.register('begin', function(to, element) {
            console.log("begin", arguments);
          });
       
          Events.scrollEvent.register('end', function(to, element) {
            console.log("end", arguments);
          });
       
          scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }  
      handleSetActive(to) {
        console.log(to);
      }


    handleScroll = () => {
            var scroll = window.scrollY;
            if(scroll >= 150){
                this.setState({ isNavFixed: true})
            }
            else{
                this.setState({ isNavFixed: false})
            }
            // this.setState({isNavFixed: false});
    };

    goToTop = () => {
        window.scrollTo(0, 0);
    }

    toggleMenu = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    render(){
        return(
            <div className={`navigation ${this.state.isNavFixed ? 'fixed-nav' : ''}`}>
                <div className='main-nav'>
                    <NavLink to='/' onClick={this.goToTop} >
                    <div className='logo'>
                        <img className="logo-dark" src={LogoDark} height='60' alt='logo' />
                        <img className="logo-light" src={LogoLight} height='60' alt='logo' />
                    </div>
                    </NavLink>
                    <nav className={`nav-menu ${this.state.isOpen ? 'is-open' : ''}`}>
                        <ul>
                            <li><Link onClick={this.toggleMenu} activeClass="active" to='home' spy={true} smooth={true} duration={1500}>Home</Link></li>
                            <li><Link onClick={this.toggleMenu} activeClass="active" to='about' spy={true} smooth={true} offset={-100} duration={1500}>About</Link></li>
                            <li><Link onClick={this.toggleMenu} activeClass="active" to='services' spy={true} smooth={true} offset={-60} duration={1500}>Services</Link></li>
                            <li><Link onClick={this.toggleMenu} activeClass="active" to='quote'  className='display-mobile' spy={true} smooth={true} offset={-40} duration={1500}>Request a Quote</Link></li>
                        </ul>
                        <span onClick={this.toggleMenu} className='close-btn'><LineIcon size='sm' effect='tada' name="close" /></span>
                    </nav>
                    <nav className='action'>
                        {/* <NavLink to='/sign-in' className='sign-in-btn'>Sign In</NavLink> */}
                        <Link to='quote' className='get-started-btn'  spy={true} smooth={true} offset={-120} duration={1500}>Let's Build</Link>
                    </nav>
                    <span onClick={this.toggleMenu} className='open-btn'><LineIcon size='md' effect='tada' name="menu" /></span>
                </div>
            </div>
        )
    }
}


export default Navigation;