import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Link, Events, scrollSpy } from 'react-scroll'
import './homepage.styles.scss';
import Showcase2 from '../../assets/images/showcase/image1.png';
import Showcase1 from '../../assets/images/showcase/image2.png';
import Slider from "react-slick";
import {NextArrow, PreviousArrow} from '../../components/Arrows/arrows.component';

import WOW from 'wow.js';

class Homepage extends Component{

    state = {
        fullname:'',
        company:'',
        service:'',
        description:'',
        email: '',
        mobile_no: '',
        isSendingEnquiry:false,
        enquirySent: false
    }

    handleInputChange = (event) => {
        this.setState({
           [event.target.name]: event.target.value
        });
    }

    handleOnSelectChange = (event) => {
      this.setState({
         [event.target.name]: event.target.value
      });
  }

    componentDidMount() {
        window.scrollTo(0, 0);
        const wow = new WOW();
        wow.init();

        window.addEventListener("scroll", this.handleScroll);
        Events.scrollEvent.register('begin', function(to, element) {
            // console.log("begin", arguments);
        });
    
        Events.scrollEvent.register('end', function(to, element) {
            // console.log("end", arguments);
        });
    
        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }  

    sendEnquiry = (e) => {
        e.preventDefault();
        let {fullname, company, service, description, email, mobile_no } = this.state;
        const data = {
            fullname, company, service, description, email, mobile_no
        }
        this.setState({isSendingEnquiry: true});

        console.log('#', data);
        // return '';

        fetch('https://artandcodes.com/api/booking', {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
        console.log('Success:', data);
        this.setState({isSendingEnquiry: false, enquirySent: true});
        })
        .catch((error) => {
        console.error('Error:', error);
        this.setState({isSendingEnquiry: false})
        });
    }

    setActiveFaq = (idx) => {
        if(this.state.activeFaq === idx){
            this.setState({activeFaq : null})
            return;
        }
        this.setState({activeFaq : idx})
    }

    render(){

        let {fullname, company, service, description, email, mobile_no, isSendingEnquiry, enquirySent } = this.state;

        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true,
            duration: 5000,
            speed: 1000,
            delay: 3000,
            autoplaySpeed: 5000,
            slidesToShow: 6,
            slidesToScroll: 1,
            pauseOnHover: true,
            className: 'client-logo-slider',
            nextArrow: <NextArrow />,
            prevArrow: <PreviousArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 6,
                    speed: 800,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true
                  }
                },
                {
                  breakpoint: 520,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false,
                    arrows: false,
                    dots: false
                  }
                }
              ]
        };

        return (
            <div>
                <div className='hero-container' id='home'>
                    <div className='side-image'></div>
                    <div className="banner-overlay"></div>
                    <div className='homepage-banner-content'>
                        <h1>Design & Develop the Future through Technology</h1>
                        {/* <h3>Smile is a life improvement and wealth management community.</h3> */}
                        <h3>We are a team of world-class software engineers, <br></br>designers and critical thinkers 
                        </h3>
                        <Link to='quote' className='gradient-btn get-started-btn wow fadeIn' data-wow-delay='0.2s'  spy={true} smooth={true} offset={-120} duration={1500}>Start A Project</Link>               
                    </div>
                </div>
                <section className='about-section' id='about'>
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-6">
                                <div className='showcase-wrapper'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className='showcase-1 wow fadeIn' data-wow-delay='0.2s'><img src={Showcase1} alt='Showcase'/></div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className='showcase-2 wow slideInUp' data-wow-delay='0.2s'><img src={Showcase2} alt='Showcase'/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>
                                We help SME's and corporate organisations to build software products from conceptualization to production since the past 3 years
                                </h3>
                                <p>
                                <strong>Art and Codes</strong> is an IT company with niche expertise and a focus on providing technological services through an agile and flexible approach to development. We have
                                worked with some of Nigeria's amazing industries to take their online presence to new height.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center clients-logos-section">
                            <div className="col-md-12">
                                <Slider {...settings}>
                                    <div><img src={require('../../assets/images/clients/agroearn.svg')} alt="agroearn"/></div>
                                    <div><img src={require('../../assets/images/clients/qualitech.png')} alt="qualitech"/></div>
                                    <div><img src={require('../../assets/images/clients/ridit.png')} alt="ridit"/></div>
                                    <div><img src={require('../../assets/images/clients/foxygreen.svg')} alt="foxygreen"/></div>
                                    <div><img src={require('../../assets/images/clients/smilehub.svg')} alt="smilehub"/></div>
                                    <div><img src={require('../../assets/images/clients/hama-medicals.svg')} alt="hama-medicals"/></div>
                                    <div><img src={require('../../assets/images/clients/kozaparkfarms.png')} alt="kozaparkfarms"/></div>
                                    <div><img src={require('../../assets/images/clients/hamagroup.svg')} alt="hamagroup"/></div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='services-section' id='services'>
                    <div className="container">
                        <h1 className='title'>Our Services</h1>
                        <div className="row justify-content-md-left">
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='0.2s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/2.svg')} alt='web design'/></div>
                                    <h2 className='title'>Web Design / Development</h2>
                                    <p>will give your website a new responsive, eye-catching, 
                                        and user-friendly design that will attract visitors and 
                                        persuade them to buy.
                                        </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='0.4s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/4.svg')} alt='web design'/></div>
                                    <h2 className='title'>Mobile App Development</h2>
                                    <p>We develop iOS and Android applications for your business, 
                                        test them for performance and efficiency, 
                                        and deploy to Google Play and Apple Store.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='0.6s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/6.svg')} alt='web design'/></div>
                                    <h2 className='title'>UI/UX Design</h2>
                                    <p>Create the product as you envisioned it, let us give you the
                                        perfect design centered around your users.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='0.8s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/5.svg')} alt='web design'/></div>
                                    <h2 className='title'>Video / Motion Design</h2>
                                    <p>Showcase your brand through explainer videos and motion graphics by our experienced designers
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='1s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/3.svg')} alt='web design'/></div>
                                    <h2 className='title'>ENTERPRISE SOFTWARE DEVELOPMENT</h2>
                                    <p>Support your business with scalable and well-integrated software that integrates the latest technology.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay='1.2s'>
                                <div className='service-item'>
                                <div className='icon'><img src={require('../../assets/images/icon/1.svg')} alt='web design'/></div>
                                    <h2 className='title'>IT Consulting</h2>
                                    <p>Contact us for all a successful optimization and transformation of your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="callus-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1>Work with industry experts from start to finish</h1>
                                <p>We have summarized our process into 5 easy steps</p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-lg-2 col-sm-6">
                                <div className='process-item wow slideInLeft' data-wow-delay='0.2s'>
                                    <h3 className='count'>1</h3>
                                    <h4>Idea</h4>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6">
                                <div className='process-item wow slideInLeft' data-wow-delay='0.2s'>
                                    <h3 className='count'>2</h3>
                                    <h4>Strategy Planning</h4>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6">
                                <div className='process-item wow slideInLeft' data-wow-delay='0.2s'>
                                    <h3 className='count'>3</h3>
                                    <h4>Development</h4>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6">
                                <div className='process-item wow slideInLeft' data-wow-delay='0.2s'>
                                    <h3 className='count'>4</h3>
                                    <h4>Testing</h4>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6">
                                <div className='process-item wow slideInLeft' data-wow-delay='0.2s'>
                                    <h3 className='count'>5</h3>
                                    <h4>Launch & Support</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quotation-section" id='quote'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h1>Tell us about your project</h1>
                                    {/* <p>call us on  <a href="tel:07066365036">0706 636 5036</a>  to discuss your requirements</p> */}
                                    <p>You can also <a href="mailto:artandcodes@gmail.com">send us a mail</a> for a FREE Estimate ,<br></br> We usually reply within 48 hours.</p>
                                    <ul className='contact-info'>
                                        <li><span className='fa fa-phone'></span> <a href="tel:07066365036">0706 636 5036</a></li>
                                        <li><span className='fa fa-envelope'></span> <a href="mailto:artandcodes@gmail.com">artandcodes@gmail.com</a></li>
                                        <li><span className='fa fa-clock-o'></span> Monday to Sunday: 24 Hours Available</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-wrap wow zoomIn" data-wow-delay='0.2s'>
                                        <form onSubmit={this.sendEnquiry} >
                                            { !enquirySent ? ( 
                                            <div className='form-block'>
                                                <div className="form-group">
                                                    <select className="form-select"  name="service" value={service}  onChange={this.handleOnSelectChange}  required>
                                                        <option value="">I am interested in</option>
                                                        <optgroup label="Services">
                                                        <option value="Mobile App Development">Mobile App Development</option>
                                                        <option value="Enterprise Software Development">Enterprise Software Development</option>
                                                        <option value="Web Development">Web Development</option>
                                                        <option value="IT Consulting">IT Consulting</option>
                                                        <option value="Digital Marketing">Digital Marketing</option>
                                                        <option value="Other">Other</option>
                                                        </optgroup>
                                                        <optgroup label="Design">
                                                        <option value="Graphics Design">Graphics Design</option>
                                                        <option value="Branding">Branding</option>
                                                        <option value="Motion Design">Motion Design</option>
                                                        <option value="UX / UI Design">UX / UI Design</option>
                                                        </optgroup>
                                                    </select>                    
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="fullname" onChange={this.handleInputChange} value={fullname} maxLength="255" placeholder="Fullname" required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="email" onChange={this.handleInputChange} value={email} maxLength="255" placeholder="Email"/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="mobile_no" onChange={this.handleInputChange} value={mobile_no} maxLength="50" placeholder="Work Phone" required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="company" onChange={this.handleInputChange} value={company} maxLength="255" placeholder="Company"/>
                                                </div>
                                                <div className="form-group ">
                                                    <textarea className="form-textarea" rows="5" name="description" onChange={this.handleInputChange} value={description} maxLength="1000" placeholder="Project description"></textarea>
                                                </div>
                                                <div className="form-group ">
                                                    <button type="submit" className="gradient-btn send-btn wow fadeUp"  data-wow-delay='0.2s' disabled={isSendingEnquiry}>
                                                         {isSendingEnquiry ? <span className='fa fa-spin fa-spinner'></span> : ' Send a Message'}
                                                    </button>
                                                </div>
                                                
                                            </div>
                                             ): (
                                            <div className="success-msg text-center">
                                                <i className='fa fa-check text-success fa-3x'></i>
                                                <h3>Thank you for your message!</h3>
                                                <p className="thank__text">We will get in touch with you regarding your request within one business day.</p>
                                            </div>
                                            )}
                                       </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

const WithRouterHomepage = withRouter(Homepage)


export default WithRouterHomepage;